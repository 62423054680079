<template>
    <div class="footer" :style="{ height:  bockHeight + 'px' }" :class="{ bordered: isBorderShown }">
        <div class="wrapper">
            <h2 v-if="$i18n.locale == 'en'">
                We're always <span v-on:click="changeMiddleWord" class="emojo"><randChar :speed="10" :text="middleText" /></span> to help
                <span>our clients with <span class="end-emojo" v-on:click="changeEndWord"><randChar :speed="10" :text="endText" /></span></span>
            </h2>
            <h2 class="ja-title" v-if="$i18n.locale == 'ja'">
                <span class="wrapp-string">
                    <span class="emojo" v-on:click="changeMiddleWord"><randChar :speed="10" :text="middleTextJa" /></span>
                    <span v-on:click="changeEndWord"><randChar :speed="10" :text="endTextJa" /></span>
                </span>
                <span>まずは、D&D promotionにご相談ください。</span>
            </h2>
            <div>
                <router-link to="contacts">
                    <span v-for="(val, key) in $t('startConversation')" v-bind:key="key">{{val}}</span>
                </router-link>
            </div>
        </div>
        <div class="bottom-panel">
            <div class="pannel-controls">
                <router-link class="a-common-hover" to="/privacy-policy">{{ $t('privacyLinkText') }}</router-link>
            </div>
            <div v-if="!isMultilangOff" class="mobile-lang-select">
                <langSelectMobile/>
            </div>
            <div class="social-networks" :class="{ 'no-lang': isMultilangOff }">
                <div class="social-networks-wrapper">
                    <template v-if="$i18n.locale == 'en'">
                        <a href="//www.facebook.com/dnd.promotion/" target="_blank" rel="noopener noreferrer">
                            <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                                <path d="M32 0C14.3269 0 0 14.3269 0 32c0 15.9721 11.702 29.2106 27 31.6113V41.25h-8.125V32H27v-7.05c0-8.02 4.7774-12.45 12.0869-12.45 3.501 0 7.1631.625 7.1631.625V21h-4.0351C38.2397 21 37 23.4667 37 25.9973V32h8.875l-1.4188 9.25H37v22.3613C52.298 61.2106 64 47.972 64 32 64 14.3269 49.6731 0 32 0z" fill-rule="evenodd"></path>
                            </svg>
                        </a>
                        <a href="//www.instagram.com/dnd.promotion/" target="_blank" rel="noopener noreferrer">
                            <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="1.414">
                                <path d="M8 0C5.827 0 5.555.01 4.702.048 3.85.088 3.27.222 2.76.42c-.526.204-.973.478-1.417.923-.445.444-.72.89-.923 1.417-.198.51-.333 1.09-.372 1.942C.008 5.555 0 5.827 0 8s.01 2.445.048 3.298c.04.852.174 1.433.372 1.942.204.526.478.973.923 1.417.444.445.89.72 1.417.923.51.198 1.09.333 1.942.372.853.04 1.125.048 3.298.048s2.445-.01 3.298-.048c.852-.04 1.433-.174 1.942-.372.526-.204.973-.478 1.417-.923.445-.444.72-.89.923-1.417.198-.51.333-1.09.372-1.942.04-.853.048-1.125.048-3.298s-.01-2.445-.048-3.298c-.04-.852-.174-1.433-.372-1.942-.204-.526-.478-.973-.923-1.417-.444-.445-.89-.72-1.417-.923-.51-.198-1.09-.333-1.942-.372C10.445.008 10.173 0 8 0zm0 1.44c2.136 0 2.39.01 3.233.048.78.036 1.203.166 1.485.276.374.145.64.318.92.598.28.28.453.546.598.92.11.282.24.705.276 1.485.038.844.047 1.097.047 3.233s-.01 2.39-.05 3.233c-.04.78-.17 1.203-.28 1.485-.15.374-.32.64-.6.92-.28.28-.55.453-.92.598-.28.11-.71.24-1.49.276-.85.038-1.1.047-3.24.047s-2.39-.01-3.24-.05c-.78-.04-1.21-.17-1.49-.28-.38-.15-.64-.32-.92-.6-.28-.28-.46-.55-.6-.92-.11-.28-.24-.71-.28-1.49-.03-.84-.04-1.1-.04-3.23s.01-2.39.04-3.24c.04-.78.17-1.21.28-1.49.14-.38.32-.64.6-.92.28-.28.54-.46.92-.6.28-.11.7-.24 1.48-.28.85-.03 1.1-.04 3.24-.04zm0 2.452c-2.27 0-4.108 1.84-4.108 4.108 0 2.27 1.84 4.108 4.108 4.108 2.27 0 4.108-1.84 4.108-4.108 0-2.27-1.84-4.108-4.108-4.108zm0 6.775c-1.473 0-2.667-1.194-2.667-2.667 0-1.473 1.194-2.667 2.667-2.667 1.473 0 2.667 1.194 2.667 2.667 0 1.473-1.194 2.667-2.667 2.667zm5.23-6.937c0 .53-.43.96-.96.96s-.96-.43-.96-.96.43-.96.96-.96.96.43.96.96z"></path>
                            </svg>
                        </a>
                    </template>
                     <template v-if="$i18n.locale == 'ja'">
                        <a href="//www.facebook.com/dnd.promotion.jp/" target="_blank" rel="noopener noreferrer">
                            <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                                <path d="M32 0C14.3269 0 0 14.3269 0 32c0 15.9721 11.702 29.2106 27 31.6113V41.25h-8.125V32H27v-7.05c0-8.02 4.7774-12.45 12.0869-12.45 3.501 0 7.1631.625 7.1631.625V21h-4.0351C38.2397 21 37 23.4667 37 25.9973V32h8.875l-1.4188 9.25H37v22.3613C52.298 61.2106 64 47.972 64 32 64 14.3269 49.6731 0 32 0z" fill-rule="evenodd"></path>
                            </svg>
                        </a>
                        <a href="//www.instagram.com/dndpromo/" target="_blank" rel="noopener noreferrer">
                            <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="1.414">
                                <path d="M8 0C5.827 0 5.555.01 4.702.048 3.85.088 3.27.222 2.76.42c-.526.204-.973.478-1.417.923-.445.444-.72.89-.923 1.417-.198.51-.333 1.09-.372 1.942C.008 5.555 0 5.827 0 8s.01 2.445.048 3.298c.04.852.174 1.433.372 1.942.204.526.478.973.923 1.417.444.445.89.72 1.417.923.51.198 1.09.333 1.942.372.853.04 1.125.048 3.298.048s2.445-.01 3.298-.048c.852-.04 1.433-.174 1.942-.372.526-.204.973-.478 1.417-.923.445-.444.72-.89.923-1.417.198-.51.333-1.09.372-1.942.04-.853.048-1.125.048-3.298s-.01-2.445-.048-3.298c-.04-.852-.174-1.433-.372-1.942-.204-.526-.478-.973-.923-1.417-.444-.445-.89-.72-1.417-.923-.51-.198-1.09-.333-1.942-.372C10.445.008 10.173 0 8 0zm0 1.44c2.136 0 2.39.01 3.233.048.78.036 1.203.166 1.485.276.374.145.64.318.92.598.28.28.453.546.598.92.11.282.24.705.276 1.485.038.844.047 1.097.047 3.233s-.01 2.39-.05 3.233c-.04.78-.17 1.203-.28 1.485-.15.374-.32.64-.6.92-.28.28-.55.453-.92.598-.28.11-.71.24-1.49.276-.85.038-1.1.047-3.24.047s-2.39-.01-3.24-.05c-.78-.04-1.21-.17-1.49-.28-.38-.15-.64-.32-.92-.6-.28-.28-.46-.55-.6-.92-.11-.28-.24-.71-.28-1.49-.03-.84-.04-1.1-.04-3.23s.01-2.39.04-3.24c.04-.78.17-1.21.28-1.49.14-.38.32-.64.6-.92.28-.28.54-.46.92-.6.28-.11.7-.24 1.48-.28.85-.03 1.1-.04 3.24-.04zm0 2.452c-2.27 0-4.108 1.84-4.108 4.108 0 2.27 1.84 4.108 4.108 4.108 2.27 0 4.108-1.84 4.108-4.108 0-2.27-1.84-4.108-4.108-4.108zm0 6.775c-1.473 0-2.667-1.194-2.667-2.667 0-1.473 1.194-2.667 2.667-2.667 1.473 0 2.667 1.194 2.667 2.667 0 1.473-1.194 2.667-2.667 2.667zm5.23-6.937c0 .53-.43.96-.96.96s-.96-.43-.96-.96.43-.96.96-.96.96.43.96.96z"></path>
                            </svg>
                        </a>
                    </template>
                </div>
            </div>
            <div v-if="!isMultilangOff" class="lang-select-wrapper">
                <langSelect/>
            </div>
            <div class="copyright">
                <p>{{ $t('copyright') }}</p>
            </div>
        </div>
        <div class="border-block" :style="{ 'border-color': borderColor }"></div>
    </div>
</template>
<script>
import randChar from './../components/rand-char';
import langSelect from './../components/lang-select';
import langSelectMobile from './../components/lang-select-mobile';

export default {
    data() {
        return {
            isMultilangOff: false,

            navElement: null,
            middleText: "",
            middleTextJa: "",
            endText: "",
            endTextJa: "",
            windowOffsetTop: 0,
            pageHeight: 0,
            isBorderShown: false,
            borderColor: 'rgb(180, 59, 139)',
            bockHeight: 0,
            colors: [
                'rgb(180, 59, 139)',
                'rgb(239, 161, 55)',
                'rgb(231, 133, 73)',
                'rgb(222, 100, 94)',
                'rgb(214, 68, 114)',
                'rgb(180, 59, 139)',
                'rgb(144, 51, 165)',
                'rgb(73, 35, 216)'
            ],
            wordsMiddle: [
                'ready',
                'online',
                'working',
                'available',
                'thinking'
            ],
            wordsMiddleJa: [
                'デジタルプロモーション',
                'ブランディング',
                'インフルエンサーマーケティング',
                '動画再生数',
                'ブランド認知'
            ],
            wordsEnd: [
                'Awards & Charts',
                'Creative Branding',
                'Digital Promotion',
                'Celebrity Marketing',
            ],
            wordsEndJa: [
                'の課題をお持ちですか？',
                'で悩んでいますか？',
                'で苦労していませんか？',
                'の課題を解決したいですか？'
            ]
        }
    },
    components: {
        randChar, 
        langSelect, langSelectMobile
    },
    created () {
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed () {
        window.removeEventListener('scroll', this.handleScroll);
    },
    watch: {
        isBorderShown() {
            if(!this.isBorderShown)
                return;
            
            this.borderColor = this.getRandValue(this.colors, this.borderColor);

            this.changeMiddleWord();
            this.changeEndWord();
        }
    },
    methods: {
        changeMiddleWord() {
            this.middleText = this.getRandValue(this.wordsMiddle, this.middleText);
            this.middleTextJa = this.getRandValue(this.wordsMiddleJa, this.middleTextJa);
        },
        changeEndWord() {
            this.endText = this.getRandValue(this.wordsEnd, this.endText);
            this.endTextJa = this.getRandValue(this.wordsEndJa, this.endTextJa);
        },
        getRandValue(array, oldValue)
        {
            let randArrValue = array[this.getRandNumber(array.length)];

            while(randArrValue == oldValue)
                randArrValue = array[this.getRandNumber(array.length)];

            return randArrValue;
        },
        getRandNumber(amount) {
            return Math.floor(Math.random() * amount);
        },
        handleScroll() {
            this.windowOffsetTop = window.pageXOffset === undefined ? document.documentElement.scrollTop : window.pageYOffset;
            
            this.isBorderShown = this.getHeightInPercents(this.windowOffsetTop) >= this.getSctionOffsetTrigger();
            let height = window.innerHeight;
            
            if(!this.isMobile && !this.isTablet()) {
                height = 800 > height ? 800 : height;
            }
            

            this.bockHeight = height - document.querySelector(".nav").offsetHeight;
        },
        getSctionOffsetTrigger() {
            const footer = document.querySelector(".footer");
            return this.getHeightInPercents(footer.offsetTop) - 10;
        },
        getHeightInPercents(height) {
            const percent = this.pageHeight / 100;
            return height / percent;
        },
        isMobile() {
            return this.getWindowWidth() <= this.mobileWidth;
        },
        isTablet() {
            return (
                this.getWindowWidth() >= this.mobileWidth &&
                this.getWindowWidth() <= this.tabletWidth
            );
        },
    },
    mounted() {
       const body = document.body, html = document.documentElement;

       this.pageHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);

       document.addEventListener('click', () => { this.langSelectActive = false });
    }
}
</script>

<style lang="scss" scoped>
@import '../scss/app';

.footer {
    position: relative;
    height: calc(100vh - 75px);
    width: 100%;
    background: #191a1a;
    overflow: hidden;
    font-family: 'Larsseit', sans-serif;
    overflow: hidden;
}

.bordered .border-block {
    border: solid 0;
    border-radius: 39px;
    border-width: 36px;
    position: absolute;
    top: -20px;
    bottom: -20px;
    left: -20px;
    right: -20px;
    @include transition(600ms ease);
}

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 10vw 160px;
    height: calc(100vh - 75px);
    position: relative;
    z-index: 2;
    overflow: hidden;

    h2 {
        color: #F0F0F0;
        @include font-size(68px);
        margin-bottom: 1.6em;
        font-weight: 400;
        line-height: 1.42em;
        font-family: $family-medium;

        span {
            white-space: nowrap;
        }
    }

    .ja-title {
        @include font-size(46px);
        font-weight: 700;
    }

    a {
        @include font-size(18px);
        position: relative;
        font-size: calc(18px + 18 * ((100vw - 1024px) / 976));
        line-height: calc(18px + 18 * ((100vw - 1024px) / 976));
        font-family: $family-regular;
        background: #F5F5F5;
        border-radius: 3px;
        border: solid 1px #F5F5F5;
        color: #191a1a;
        padding: 0.8em 1em 0.7em;
        overflow: hidden;
        display: inline-block;
        text-decoration: none;
        cursor: pointer;        
        @include transition(background-color 400ms ease, border 400ms ease);

        $charCoutn: 20;
        $duration: 400ms;

        span {
            position: relative;
            @include transition(all 400ms ease, border 400ms ease);
            @include animation(wave-off $duration ease-out);
            @include animation-fill-mode(forwards);
        }

        &:hover {
            background: #191a1a;
            border: solid 1px #3D4040;
                
            span {
                @include animation(wave-on $duration);
                @for $i from 1 through $charCoutn {
                    &:nth-child( #{$charCoutn}n + #{$i} ) {
                        @include animation-delay($duration / $charCoutn * $i);
                    }
                }
                @include animation-fill-mode(forwards);
            }
        }
    }
}

@media (max-height: 800px){

    .wrapper {
        height: auto;
        padding: 60px 10vw 100px;
    }
}

.wrapp-string {
    display: inline-block;
    width: auto;
}

.emojo {
    color: #F6BD25;
    cursor: pointer;
}

.end-emojo {
    cursor: pointer;
    position: relative;
}

.bottom-panel {  
    bottom: 52px;
    left: 13em;
    right: 13em;
    position: absolute;
    z-index: 12;
}

.pannel-controls {
    margin-bottom: 12px;
        
    a {
        color: #DCE6E6;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        margin-right: 24px;
        @include font-size(14px);
        font-size: calc(15px + 2 * ((100vw - 1280px) / 640));
        line-height: calc(15px + 2 * ((100vw - 1280px) / 640));
        display: inline-block;
        transition: 300ms cubic-bezier(0.86, 0, 0.07, 1);
        font-family: $family-medium;
        text-decoration: none;
    }
}    

.copyright {
    p {
        color: #626666;
        @include font-size(14px);
        line-height: 14px;
        font-family: $family-light;
    }
}

.social-networks {
    position: absolute;
     bottom: -10px;
    right: 0;

    &.no-lang {
        bottom: -23px;
    }

    .social-networks-wrapper {
        display: flex;
    }

    a {
        display: block;
        margin-bottom: 16px;
        width: 0.8vw;
        min-width: 24px;
        opacity: 0.7;
        margin-left: 16px;
    }

    svg {
        fill: #DCE6E6;
        transition: 300ms ease;
    }

    svg:hover {
        fill: rgb(51, 50, 50);
        transition: 300ms ease;
    }
}

.mobile-lang-select {
    display: none;
}

.lang-select-wrapper {
    right: 40px;
    position: absolute;
    bottom: 0;
}
        
@include keyframes(wave-on) {
    0% {
        color: #191a1a;
        top:0;
    }
    10% {
        color: rgb(246, 189, 37);
    }
    20% {
        color: rgb(239, 161, 55);
    }
    30% {
        color: rgb(231, 133, 73);
        top:2px;
    }
    40% {   
        color: rgb(222, 100, 94);
        top:2px;
    }
    50% {
        color: rgb(214, 68, 114);
        top:2px;
    }
    60% {
        color: rgb(180, 59, 139);
        top:2px;
    }
    70% {
        color: rgb(144, 51, 165);
    }
    80% {
        color: rgb(73, 35, 216);
    }
    100% {
        color: #DCE6E6;
        top:0;
    }
}

@include keyframes(wava-off) {
    0% {
        color: #DCE6E6;
    }
    100% {
        color: #191a1a;
    }
}

@media (max-width: 992px) {

    .mobile-lang-select {
        display: block;
        padding-top: 10px;
    }

    .lang-select-wrapper {
        display:none;
    }

    .footer {
        height: calc(100vh - 60px);
    }

    .copyright {
        display: inline-block;
        padding-top: 7px;
    }

    .wrapper {
        h2 {
            font-size: 7vw;
            text-align: center;

            span {
                white-space: normal;
            }

            .emojo {
                position: relative;
                display: block;
                width: 100%;
                text-align: center;
            }

            .end-emojo {
                display: block;
                text-align: center;
                width: 100%;
                height: 95px;
            }

            .end-emojo span{
                position: absolute;
                display: block;
                white-space: nowrap;
                left: 50%;
                @include translateX(-50%);
            }
        }

        .ja-title {
            font-size: 7vw;
            text-align: center;
            .emojo {
                display: inline;
            }
        }
        
        div {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        a {
            @include font-size(18px);
            padding: 0.8em 1em 0.7em;
            line-height: 1em;
        }
    }

    .bottom-panel {
        bottom: 30px;
        left: 40px;
        right: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .pannel-controls {
        margin-bottom: 0;
        a {
            margin: 0 5px;
        }
    }

    .social-networks {
        position: static;
    }

    .social-networks-wrapper {
        display: flex;
        justify-content: space-between;

        a {
            margin: 10px;
        }
    }
}    

@media (max-width: 576px) {
    .footer {
        min-height: 500px;
        @include transition(all 100ms);
    }
    .wrapper {
        
        h2 {
            font-size: 7vw;
            margin-bottom: 3vh;

            .end-emojo {
                height: 35px;
            }
        }

        .ja-title {
            font-size: 7vw;
            text-align: center;
            .emojo {
                display: inline;
            }
        }
    }
}
</style>