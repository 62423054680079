<template>
    <span v-html="output"></span>
</template>
<script>

export default {
    data() {
        return {
            chars: 'abcdefghijklmnopqrstuvwxyz#%^+=-',
            output : "",
            clen: 0,       
            si: 0,
            stri: 0,
            block: "",
            fixed: ""
        }
    },
    props: {
        text: String,
        speed: {
            type: Number,
            default: 50
        },
        increment: {
            type: Number,
            default: 4
        }
    },
    watch: {
        text() {
            this.run();
        }
    },
    methods: {
        run() {
            this.output = "",
            this.clen = this.text.length,       
            this.si = 0,
            this.stri = 0,
            this.block = "",
            this.fixed = "";
            this.rustle(this.clen*this.increment+1);
        },
        rustle(i) {
            const _this = this;        
            setTimeout(function () {
                if (--i) {
                    _this.rustle(i);
                }

                _this.nextFrame(i);
                
                _this.si = _this.si + 1;

            }, _this.speed);
        },
        nextFrame(){
            for (let i = 0; i < this.clen - this.stri; i++) {
                const num = Math.floor(this.chars.length * Math.random());
                const letter = this.chars.charAt(num);
                this.block = this.block + letter;
            }

            if (this.si == (this.increment-1)) {
                this.stri++;
            }
  
            if (this.si == this.increment) {
                this.fixed = this.fixed +  this.text.charAt(this.stri - 1);
                this.si = 0;
            }

            this.output = this.fixed + this.block;
            
            this.block = "";
        }
    }, 
    mounted() {
        this.run();
    }
}

</script>